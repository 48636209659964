import React, { useEffect, useState } from 'react';
import { Flex, Box, Image, Button, Text } from 'rebass';
import { Link, graphql } from 'gatsby';
import { Colors } from '../theme';

const logos = [
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/Antica%20Osteria%20del%20Porto/aanticaosteriadelporto-logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/La%20Cucina%20di%20Alice%20Lugano/alacucinadialice---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/La%20Dispensa/aladispenza---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/Convivio%20Lugano/aconvivio---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/Argentino%20Lugano/aargentino---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/Gabbani%20Lugano/agabbani---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/La%20Trattoria%20Lugano/alatrattoria---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/Braceria%20Elvetica%20Lugano/abraceria-elvetica---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/La%20Stala%20Mezzovico/alastala---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/Bee%20Lugano/abee---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/Acqua%20%26%20Farina%20Lugano/aacquaefarina---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/Anema%20%26%20Core/aanema-e-core---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/Balmelli%20Collina%20D%27Oro/abalmelli---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/Cantinetta%20Caprese%20Bissone/acantinetta-caprese---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/Giangusto%20Lugano/agiangusto---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/Giardino%20Sorengo/agiardino---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/Grotto%20al%20Bosco%20Collinda%20d%27Oro/agrotto-al-bosco---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/Lido%20Conca%20d%27Oro%20Paradiso/alidoconcadoro---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/Pizza%20Burger%20Lugano/apizzaburger---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/Portici%20Lugano/aportici---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/Spaghetti%20Store%20Lugano/aspaghettistore-logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/The%20Lab%20Barbacoa%20Comano/athelabbarbacoa---logotrue.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/The%20Lab%20Lugano/athe-lab---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/Tabla%20Montagnola/tablaweb---logo.png',
  'https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/La%20Fermata%20Lugano/lafermataweb---logo.png',
];

function getShuffledArr(arr) {
  return [...arr].map((_, i, arrCopy) => {
    const rand = i + Math.floor(Math.random() * (arrCopy.length - i));
    [arrCopy[rand], arrCopy[i]] = [arrCopy[i], arrCopy[rand]];
    return arrCopy[i];
  });
}

const ShowCaseRestaurants = ({ totalRestaurants }) => {
  const [restaurants, setRestaurants] = useState([]);
  useEffect(() => {
    setRestaurants(getShuffledArr(logos));
  }, []);
  return (
    <div>
      <Text px={2} mb={1} textAlign="center" fontSize={5}>
        {`Scopri ${totalRestaurants} `}
        <Link
          style={{ color: Colors.RED, textDecoration: 'none' }}
          to="/ristoranti"
        >
          ristoranti
        </Link>
      </Text>
      <div className="showcase-restaurants" style={{ position: 'relative' }}>
        <Flex
          className="showcase-restaurants-logos"
          py={4}
          mx={[0, 0, -2]}
          flexWrap="wrap"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          {restaurants.slice(0, 8).map((l, i) => (
            <Box width={[1 / 5, 1 / 10, 1 / 10, 1 / 10]} key={i} px={2}>
              <Image alt="logo restaurant partner" src={l} />
            </Box>
          ))}
        </Flex>
        <Flex
          className="showcase-restaurants-overlay"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <Link to="/ristoranti/">
              <Button variant="primary">{`SCOPRI ${totalRestaurants} RISTORANTI`}</Button>
            </Link>
          </Box>
        </Flex>
      </div>
    </div>
  );
};

export default ShowCaseRestaurants;
