import React, { useState, useEffect } from 'react';
import { Flex, Box, Image, Text, Link } from 'rebass';
import GhostContentAPI from '@tryghost/content-api';
import { format } from 'date-fns';
import { Colors } from '../theme';

const ghostApi = new GhostContentAPI({
  url: 'https://blog.mangiami.ch',
  key: '81901a77f5a942a1ccc7bb160b',
  version: 'v2',
});

function GhostPosts() {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    const fetchPosts = async () => {
      const data = await ghostApi.posts.browse({
        limit: 3,
        include: 'tags,authors',
      });
      setPosts(data);
    };
    fetchPosts();
  }, []);
  if (!posts) {
    return null;
  }
  return (
    <Box width={1}>
      <Text px={2} mb={3} textAlign="center" fontSize={5}>
        Ultimi articoli dal nostro{' '}
        <Link
          style={{ color: Colors.RED, textDecoration: 'none' }}
          href="https://blog.mangiami.ch"
        >
          Blog
        </Link>
      </Text>
      <Flex flexDirection={['column', 'row']}>
        {posts.map(post => (
          <Box key={post.id} p={3} width={[1, 1, 1 / 3]}>
            <article style={{ height: '100%' }} className="post post-card">
              <a href={post.url} className="permalink">
                <div
                  className="featured-image"
                  style={{ backgroundImage: `url(${post.feature_image})` }}
                />
              </a>
              <div className="entry-header">
                <Flex mb={3} justifyContent="center">
                  <span
                    style={{ color: '#999999', fontWeight: '300' }}
                    className="category"
                  >
                    {post.tags.map(tag => tag.name.toUpperCase()).join(', ')}
                  </span>
                </Flex>
                <Link
                  css={{
                    textDecoration: 'none',
                    ':hover': { color: Colors.RED },
                  }}
                  color="black"
                  href={post.url}
                  rel="bookmark"
                >
                  <Text fontSize={3} textAlign="center">
                    {post.title}
                  </Text>
                </Link>
              </div>
              <Box px={3}>
                <Box width={1} style={{ height: '1px' }} bg="#cccccc4d" />
              </Box>
              <Flex p={3} alignItems="center" justifyContent="space-between">
                <a
                  style={{ textDecoration: 'none' }}
                  href={post.authors[0].url}
                  rel="author"
                >
                  <Flex alignItems="center">
                    <Image
                      mr={2}
                      className="avatar"
                      src={post.authors[0].profile_image}
                      alt="avatar"
                      width={40}
                      css={{
                        borderRadius: 99999,
                      }}
                    />
                    <Text fontSize={1} color="#999">
                      {post.authors[0].name}
                    </Text>
                  </Flex>
                </a>
                <div className="published-date">
                  <Text fontSize={1} color="#999">
                    {format(new Date(post.published_at), 'dd MMM yyyy')}
                  </Text>
                </div>
              </Flex>
            </article>
          </Box>
        ))}
      </Flex>
    </Box>
  );
}

export default GhostPosts;
