import React, { useState } from 'react';
import {
  Flex,
  Box,
  Text,
  Heading,
  Image,
  Button,
  Link as ExternaLink,
} from 'rebass';
import Typist from 'react-typist';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import img2Phones from '../images/2phones_splash.png';
import ShowCaseRestaurants from '../components/ShowcaseRestaurants';
import SEO from '../components/SEO';
import GhostPosts from '../components/GhostPosts';
import Categories from '../components/Categories';
import { Colors } from '../theme';

const imgApple = require('../images/app_store.svg');
const imgGoogle = require('../images/play_store.png');

const suggestions = [
  'prosciutto e melone',
  'vitello tonnato',
  'frutti di mare',
  'carbonara',
  'fajitas',
  'tartare di manzo',
  'puntine',
  'fritto misto',
  'cordon bleu',
  'fajitas',
  'poke',
  'uramaki',
  'tortino al cioccolato',
];

const IndexPage = ({ data }) => {
  const totalRestaurants =
    data && data.mangiami && data.mangiami.listRestaurants.length;
  const [typistIndex, setTypistIndex] = useState(0);
  return (
    <Layout headerRight="restaurants" totalRestaurants={totalRestaurants}>
      <SEO
        pageTitle="Cosa vuoi mangiare oggi? - Mangiami"
        pageDescription="Hai fame? Hai una voglia matta di.. e non sai dove poterlo trovare? Vuoi sapere più informazioni su un ristorante prima di andarci? Mangiami ha la risposta."
        pageImage="https://d12unveku4b8zl.cloudfront.net/restaurants/lugano/0000%20static/mangiami%20thumbnail%20-%20final.png"
        pageUrl="/"
      />
      <Box
        css={`
          position: relative;
          min-height: 500px;
          @media only screen and (min-width: 576px) {
            height: 700px;
          }
        `}
      >
        <div id="stripes" style={{ zIndex: -1 }} />
        <div style={{ maxWidth: 1200, margin: '0 auto', position: 'relative' }}>
          <Box
            width={[0, 350, 400, 600]}
            mr={[2, 4, 0]}
            mt={[0, 0, 80]}
            style={{
              height: '600px',
              position: 'absolute',
              right: 0,
              top: 0,
              backgroundImage: `url(${img2Phones})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
            }}
          />
        </div>
        <div style={{ maxWidth: 1200, margin: '0 auto' }}>
          <Flex px={[3, 4, 0]} width={1} py={[4, 5, 6]} flexDirection="column">
            <Box py={2} width={1}>
              <Heading
                as="h1"
                textAlign={['center', 'left']}
                color="#fff"
                fontWeight="500"
                marginBottom={4}
                fontSize={[4, 5, 6]}
              >
                Cosa vuoi mangiare oggi?
              </Heading>
              <Text
                textAlign={['center', 'left']}
                color="#fff"
                fontSize={[3, 4]}
              >
                Ho voglia di{' '}
                <Typist
                  key={typistIndex}
                  onTypingDone={() => setTypistIndex(typistIndex + 1)}
                  avgTypingDelay={100}
                  cursor={{ show: false }}
                >
                  {suggestions.map(word => [
                    <span>{word}</span>,
                    <Typist.Backspace count={word.length} delay={700} />,
                  ])}
                </Typist>
              </Text>
            </Box>
            <Box py={3} width={1}>
              <Flex flexDirection="column">
                <Flex
                  mb={[2, 5]}
                  py={[3, 0]}
                  justifyContent={['center', 'flex-start']}
                  flexDirection="row"
                >
                  <Link className="hidden-xs" to="/esplora/">
                    <Button px={[3]} fontSize={[3]} variant="jumbo">
                      ESPLORA 2400 PIATTI
                    </Button>
                  </Link>
                  <Link className="visible-xs" to="/esplora/">
                    <Button px={[3]} fontSize={[3]} variant="secondary">
                      ESPLORA 2400 PIATTI
                    </Button>
                  </Link>
                </Flex>
                <Flex
                  justifyContent={['center', 'flex-start']}
                  flexWrap="wrap"
                  flexDirection="row"
                  py={[3, 3]}
                >
                  <Box py={[0, 2, 0]} width={['auto', 1, 1, 'auto']}>
                    <ExternaLink href="https://apps.apple.com/ch/app/mangiami-food-app/id1439263899?l=it">
                      <Image
                        alt="App store download"
                        mr={3}
                        width={[140, 200]}
                        src={imgApple}
                      />
                    </ExternaLink>
                  </Box>
                  <Box py={[0, 2, 0]} width={['auto', 1, 1, 'auto']}>
                    <ExternaLink href="https://play.google.com/store/apps/details?id=ch.mangiami.app">
                      <Image
                        alt="Play store download"
                        width={[143, 204]}
                        src={imgGoogle}
                      />
                    </ExternaLink>
                  </Box>
                </Flex>
                <Text
                  mb={[3, 0]}
                  textAlign={['center', 'left']}
                  fontSize={[3, 4]}
                  color="#fff"
                  lineHeight={1.35}
                >
                  Ora nel Luganese e Mendrisiotto.
                  <br />
                  Presto in tutto il Ticino.
                </Text>
                <Box
                  style={{ textAlign: 'center' }}
                  py={3}
                  className="visible-xs"
                >
                  <Image
                    alt="App preview iPhone"
                    src={img2Phones}
                    style={{ maxHeight: '350px' }}
                  />
                </Box>
              </Flex>
            </Box>
          </Flex>
        </div>
      </Box>
      <Box my={4}>
        <Box
          style={{
            borderColor: Colors.RED,
            borderWidth: 2,
            borderRadius: 4,
            borderStyle: 'solid',
            maxWidth: 1152,
            margin: '0 auto',
          }}
          p={3}
        >
          <Text textAlign="center" fontSize={5} mb={2}>
            Avviso ⚠️
          </Text>
          <Text textAlign="center" fontSize={3}>
            Periodo di riapertura: gli orari dei ristoranti potrebbero variare
          </Text>
        </Box>
      </Box>
      <Box my={4}>
        <Categories />
      </Box>
      <div style={{ maxWidth: 1200, margin: '0 auto' }}>
        <Flex mt={[4, 5]}>
          <ShowCaseRestaurants totalRestaurants={totalRestaurants} />
        </Flex>
      </div>
      <Box py={[4, 5]} style={{ maxWidth: 1200, margin: '0 auto' }}>
        <GhostPosts />
      </Box>
      <Box py={[3, 4]}>
        <Flex justifyContent="center" flexWrap="wrap" flexDirection="row">
          <Box py={[0, 2, 0]}>
            <ExternaLink href="https://apps.apple.com/ch/app/mangiami-food-app/id1439263899?l=it">
              <Image
                alt="App store download"
                mr={3}
                width={[140, 200]}
                src={imgApple}
              />
            </ExternaLink>
          </Box>
          <Box py={[0, 2, 0]}>
            <ExternaLink href="https://play.google.com/store/apps/details?id=ch.mangiami.app">
              <Image
                alt="Play store download"
                width={[143, 204]}
                src={imgGoogle}
              />
            </ExternaLink>
          </Box>
        </Flex>
      </Box>
    </Layout>
  );
};

export const query = graphql`
  query {
    mangiami {
      listRestaurants(city: lugano) {
        id
      }
    }
  }
`;

export default IndexPage;
