import React from 'react';
import { Link } from 'gatsby';
import { Flex, Box, Text } from 'rebass';
import slugify from 'slugify';

const lasciatiIspirare = require('../images/categories/lasciatiispirare200.png');
const asiatica = require('../images/categories/asiatica200_1.png');
const burger = require('../images/categories/burger200_1.png');
const carne = require('../images/categories/carne200.png');
const colazione = require('../images/categories/colazione200.png');
const delivery = require('../images/categories/delivery200.png');
const dessert = require('../images/categories/dessert200.png');
const drinks = require('../images/categories/drink200.png');
const grill = require('../images/categories/grill200.png');
const gelato = require('../images/categories/icecream200_1.png');
const insalata = require('../images/categories/insalata200.png');
const internazionale = require('../images/categories/internazionale200.png');
const pasta = require('../images/categories/pasta200.png');
const pesce = require('../images/categories/pesce200_1.png');
const pizza = require('../images/categories/pizza200_1.png');
const risotto = require('../images/categories/risotto_1.png');
const sandwich = require('../images/categories/sandwich200.png');
const takeaway = require('../images/categories/takeaway200.png');
const ticinese = require('../images/categories/ticinese200.png');
const tartare = require('../images/categories/tartare200.png');
const vegetariana = require('../images/categories/vegetariana200.png');
const vegana = require('../images/categories/vegana200.png');

const TAGS = [
  {
    fr: 'Poisson',
    id: '6b3c469b-5b79-431d-a122-c58575758c93',
    it: 'Pesce',
    de: 'Fisch',
    en: 'Seafood',
    imageUrl: pesce,
  },
  {
    fr: 'Tessinoise',
    id: 'c68a12f7-fb65-42a5-9b61-c9ab6c659551',
    it: 'Ticinese',
    de: 'Tessiner',
    en: 'Local',
    imageUrl: ticinese,
  },
  {
    fr: 'Pizza',
    id: 'a4002d95-4447-463b-b463-d6590633f6f3',
    it: 'Pizza',
    de: 'Pizza',
    en: 'Pizza',
    imageUrl: pizza,
  },
  {
    fr: 'Viande',
    id: '8eed4a6d-3c48-48ae-822b-e394a9d54d05',
    it: 'Carne',
    de: 'Fleisch',
    en: 'Meat',
    imageUrl: carne,
  },
  {
    fr: 'Burger',
    id: 'ed93b2fd-1a53-4c44-917b-c1389e6d7575',
    it: 'Burger',
    de: 'Burger',
    en: 'Burger',
    imageUrl: burger,
  },
  {
    fr: 'Grill',
    id: 'd9b2ae99-6ddc-473f-b455-304e611d42f1',
    it: 'Grill',
    de: 'Grill',
    en: 'BBQ',
    imageUrl: grill,
  },
  {
    fr: 'Tartare',
    id: 'bfe74355-939d-4582-ab9c-72768719a13c',
    it: 'Tartare',
    de: 'Tatar',
    en: 'Tartare',
    imageUrl: tartare,
  },
  {
    fr: 'Végétarienne',
    id: 'bbdca54d-1ad5-4086-8bdb-4f6c5583fb31',
    it: 'Vegetariana',
    de: 'Vegetarische',
    en: 'Vegetarian',
    imageUrl: vegetariana,
  },
  {
    fr: 'Végétalienne',
    id: '2a314884-7b61-4b73-9047-04b58e652630',
    it: 'Vegana',
    de: 'Vegan',
    en: 'Vegan',
    imageUrl: vegana,
  },
  {
    fr: 'Asiatique',
    id: '5352e55c-956b-4093-8ffe-7f051841c1d5',
    it: 'Asiatica',
    de: 'Asiatische',
    en: 'Asian',
    imageUrl: asiatica,
  },
  {
    fr: 'Livraison',
    id: '0124242f-fd7b-449f-848f-7bdfadfa594e',
    it: 'Delivery',
    de: 'Lieferung',
    en: 'Delivery',
    imageUrl: delivery,
  },
  {
    fr: 'Takeaway',
    id: 'f9a40969-4a8f-402f-ada6-97cc380768d4',
    it: 'Takeaway',
    de: 'Takeaway',
    en: 'Takeaway',
    imageUrl: takeaway,
  },
  {
    fr: 'Pâtes',
    id: '85a37d8a-970f-4d91-8023-8adddf0f66c5',
    it: 'Pasta',
    de: 'Pasta',
    en: 'Pasta',
    imageUrl: pasta,
  },
  {
    fr: 'Drinks',
    id: '572c6fbf-e312-4852-949f-864768273ac5',
    it: 'Drinks',
    de: 'Drinks',
    en: 'Drinks',
    imageUrl: drinks,
  },
  {
    fr: 'Glace',
    id: 'f0247341-e76a-4678-936b-c73775be215d',
    it: 'Gelateria',
    de: 'Eisdiele',
    en: 'Ice cream',
    imageUrl: gelato,
  },
  {
    fr: 'Risotto',
    id: '2a26f11d-c478-4681-9693-439563d364a3',
    it: 'Risotto',
    de: 'Risotto',
    en: 'Risotto',
    imageUrl: risotto,
  },
  {
    fr: 'Internationale',
    id: 'e7176419-b85e-49bf-bd05-af5f65b60463',
    it: 'Internazionale',
    de: 'Internationale',
    en: 'International',
    imageUrl: internazionale,
  },
  {
    fr: 'Sandwich',
    id: 'a15eca7b-4efd-42fe-9af5-f69c3128dc6e',
    it: 'Panino',
    de: 'Sandwich',
    en: 'Sandwich',
    imageUrl: sandwich,
  },
  {
    fr: 'Salade',
    id: '56de5284-e56c-4ada-af9a-cef8e5b60b0e',
    it: 'Insalata',
    de: 'Salat',
    en: 'Salad',
    imageUrl: insalata,
  },
  {
    fr: 'Dessert',
    id: '9d87ebbb-5282-483c-8afc-560252985162',
    it: 'Dessert',
    de: 'Dessert',
    en: 'Dessert',
    imageUrl: dessert,
  },
  {
    fr: 'Petit dejeuner',
    id: 'f9c79d88-9e1a-447f-b6c1-ce2b2334d1c3',
    it: 'Colazione',
    de: 'Frühstück',
    en: 'Breakfast',
    imageUrl: colazione,
  },
];

function Categories() {
  return (
    <Box py={4}>
      <Text px={2} mb={4} textAlign="center" fontSize={5}>
        Ricerca per categoria
      </Text>
      <Flex style={{ maxWidth: 1200, overflow: 'hidden' }} mx="auto">
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          flexWrap="wrap"
        >
          <Link
            style={{ textDecoration: 'none' }}
            to="/esplora/lasciati-ispirare/"
          >
            <Box width={[100, 130]} height={[100, 130]} m={2}>
              <Flex
                width={[100, 130]}
                height={[100, 130]}
                p={['6px', '10px']}
                style={{
                  backgroundImage: `url(${lasciatiIspirare})`,
                  backgroundSize: 'cover',
                  justifyContent: 'flex-end',
                  flexDirection: 'column',
                  borderRadius: 6,
                }}
              >
                <Box>
                  <Text color="white" fontSize={16}>
                    Lasciati ispirare
                  </Text>
                </Box>
              </Flex>
            </Box>
          </Link>
          {TAGS.map(tag => (
            <Link
              style={{ textDecoration: 'none' }}
              to={`/esplora/${slugify(tag.it.toLowerCase())}/`}
              key={tag.id}
            >
              <Box width={[100, 130]} height={[100, 130]} m={2}>
                <Flex
                  width={[100, 130]}
                  height={[100, 130]}
                  p={['6px', '10px']}
                  style={{
                    backgroundImage: `url(${tag.imageUrl})`,
                    backgroundSize: 'cover',
                    justifyContent: 'flex-end',
                    flexDirection: 'column',
                    borderRadius: 6,
                  }}
                >
                  <Box>
                    <Text color="white" fontSize={[14, 16]}>
                      {tag.it}
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </Link>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
}

export default Categories;
